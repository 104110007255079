<template>
  <div>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="itemsFiltered"
        :items-per-page="15"
        :loading="loading"
        :item-class="
          (el) => (el.archived ? 'grey--text clickable' : 'clickable')
        "
        @click:row="(item) => showType(item.id)"
      >
        <template v-slot:item.division="{ item }">
          <LookupValue short :value="item.division" />
        </template>
        <template v-slot:item.grade="{ item }">
          <LookupValue short :value="item.grade" />
        </template>
        <template v-slot:item.semester="{ item }">
          <LookupValue short :value="item.semester" />
        </template>
        <template v-slot:item.subject="{ item }">
          <LookupValue short :value="item.subject" />
        </template>
        <template v-slot:item.creator="{ item }">
          <PersonItem :value="item.creator" />
        </template>
        <template v-slot:item.more="{ item }">
          <v-tooltip top v-if="item.hidden">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"><v-icon>mdi-eye-off</v-icon></span>
            </template>
            <span>Versteckt bis freigeschaltet wird</span>
          </v-tooltip>

          <v-tooltip top v-if="item.autogenerate">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"><v-icon>mdi-cogs</v-icon> </span>
            </template>
            <span>automatisch generiert</span>
          </v-tooltip>

          <v-tooltip top v-if="item.archived">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"
                ><v-icon>mdi-archive</v-icon>
              </span>
            </template>
            <span>es werden keine neuen Portfolios mehr erstellt</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <router-view />
  </div>
</template>

<script>
import { searchPerson } from "common/utils/people.js";
import LookupValue from "common/components/LookupValue.vue";
import PersonItem from "@/components/PersonItem.vue";
export default {
  name: "PortfolioTypes",
  props: ["search"],
  components: { LookupValue, PersonItem },
  data: () => ({
    filter: {
      creator: null,
    },
    headers: [
      { text: "Name", value: "description" },
      { text: "Abteilung", value: "division" },
      { text: "Stufe", value: "grade" },
      { text: "Sem.", value: "semester" },
      { text: "Fach", value: "subject" },
      { text: "von", value: "creator", sortable: false },
      { text: "", value: "more" },
    ],
    items: [],
    loading: false,
  }),
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (item) =>
          !this.search ||
          item.description.toLowerCase().includes(this.search.toLowerCase()) ||
          (item.creator && searchPerson(item.creator, this.search))
      );
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "portfolio/type",
        query: "archived",
      });
      this.loading = false;
    },
    showType(id) {
      this.$router.push({
        name: "PortfolioTypeDetails",
        params: { id: id },
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "PortfolioTypes") {
      this.getData();
    }
    next();
  },
  created() {
    this.getData();
  },
};
</script>
